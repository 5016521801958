class MessageBroker extends EventTarget {
    #Events = new Set([
        'onServiceWorkerMessage',
        'onServiceWorkerMessageError'
    ]);

    constructor() {
        super();
    }

    async sendMessageToServiceWorker(message) {
        const messageChannel = new MessageChannel();
        const { port1, port2 } = messageChannel;

        const registeredServiceWorker = await o365.serviceWorker.registeredServiceWorker;

        port1.addEventListener('onmessage', (event) => {
            super.dispatchEvent(new CustomEvent('onServiceWorkerMessage', {
                serviceWorkerEvent: event,
                port1,
                port2
            }));
        });

        port2.addEventListener('onmessageerror', (event) => {
            super.dispatchEvent(new CustomEvent('onServiceWorkerMessageError', {
                serviceWorkerEvent: event,
                port1,
                port2
            }));
        });

        registeredServiceWorker.active.postMessage(message, port2);
    }
}

export default new MessageBroker();
